<template>
  <span hidden />
</template>

<script>
import '@/assets/theme-light.scss';

export default {
  name: 'theme-light',
};
</script>

<style scoped>

</style>
